import React from 'react';

const VehicleCard = ({ vehicle }) => {
  const defaultImageUrl = 'https://media.istockphoto.com/id/1472933890/vector/no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-placeholder.jpg?s=612x612&w=0&k=20&c=Rdn-lecwAj8ciQEccm0Ep2RX50FCuUJOaEM8qQjiLL0=';

  const handleImageError = (e) => {
    e.target.src = defaultImageUrl;
  };

  return (
    <div className="card bg-gray-900 w-72 shadow-md m-2 transform transition-transform duration-300 hover:scale-105 hover:shadow-lg">
      <figure className="overflow-hidden">
        <img
          src={vehicle.image_url || defaultImageUrl}
          alt={vehicle.name}
          className="h-48 w-full object-cover transition-transform duration-300 hover:scale-110"
          onError={handleImageError}
        />
      </figure>
      <div className="card-body p-4">
        <h2 className="card-title text-lg text-white">
          {vehicle.name}
          <div className="badge badge-secondary ml-2">{vehicle.manufacturer}</div>
        </h2>
        <p className="text-sm text-gray-400">Preis: ${vehicle.price}</p>
        <p className="text-sm text-gray-400">Kofferraum: {vehicle.trunk_capacity} L</p>
        <p className="text-sm text-gray-400">Kraftstoff: {vehicle.fuel_type}</p>
        <div className="card-actions justify-end">
          <div className="badge badge-outline text-white border-white">{vehicle.category}</div>
        </div>
      </div>
    </div>
  );
};

export default VehicleCard;