import React, { useEffect, useState } from 'react';

const AllVehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const [sortedVehicles, setSortedVehicles] = useState({});

  useEffect(() => {
    fetch('http://45.142.107.195:8002/vehicles')
      .then(response => response.json())
      .then(data => {
        // Sortiere die Fahrzeuge nach Kategorie
        const sorted = data.reduce((acc, vehicle) => {
          const category = vehicle.category.toUpperCase();
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(vehicle);
          return acc;
        }, {});

        // Sortiere die Kategorien alphabetisch, aber "SOON" immer ganz unten
        const sortedCategories = Object.keys(sorted).sort((a, b) => {
          if (a === 'SOON') return 1;
          if (b === 'SOON') return -1;
          return a.localeCompare(b);
        }).reduce((acc, key) => {
          acc[key] = sorted[key];
          return acc;
        }, {});

        setSortedVehicles(sortedCategories);
      })
      .catch(error => console.error('Fetch error:', error));
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl mb-4">Alle Fahrzeuge</h1>
      {Object.keys(sortedVehicles).map(category => (
        <div key={category}>
          <h2 className="text-2xl mb-2">{category}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
            {sortedVehicles[category].map(vehicle => (
              <div key={vehicle.id} className="card bg-gray-900 shadow-md">
                <figure>
                  <img src={vehicle.image_url} alt={vehicle.name} className="h-48 w-full object-cover" />
                </figure>
                <div className="card-body">
                  <h2 className="card-title text-lg text-white">
                    {vehicle.name}
                    <div className="badge badge-secondary ml-2">{vehicle.manufacturer}</div>
                  </h2>
                  <p className="text-sm text-gray-400">Preis: ${vehicle.price}</p>
                  <p className="text-sm text-gray-400">Kofferraum: {vehicle.trunk_capacity} L</p>
                  <p className="text-sm text-gray-400">Kraftstoff: {vehicle.fuel_type}</p>
                  <div className="card-actions justify-end">
                    <div className="badge badge-outline text-white border-white">{vehicle.category}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllVehicles;