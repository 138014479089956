import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userParam = urlParams.get('user');
    if (userParam) {
      const userData = JSON.parse(decodeURIComponent(userParam));
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      window.history.replaceState({}, document.title, "/");
      navigate('/');
    } else {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }

    // Fetch categories from the server
    fetch('http://45.142.107.195:8002/categories')
      .then(response => response.json())
      .then(data => setCategories(data.map(category => category.toUpperCase())))
      .catch(error => console.error('Fetch error:', error));
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    navigate('/');
  };

  return (
    <div className="navbar bg-gray-800 text-white">
      <div className="flex-1">
        <Link to="/" className="btn btn-ghost text-xl text-white">Harmony Motors</Link>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          <li><Link to="/all-vehicles" className="text-white">Alle Fahrzeuge</Link></li>
          <li>
            <details>
              <summary className="text-white">Kategorien</summary>
              <ul className="bg-gray-800 rounded-t-none p-2">
                {categories.map(category => (
                  <li key={category}><Link to={`/category/${category.toLowerCase()}`} className="text-white">{category}</Link></li>
                ))}
              </ul>
            </details>
          </li>
        </ul>
        {user ? (
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`} alt="avatar" />
              </div>
            </label>
            <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-gray-800 rounded-box w-52">
              <li><Link to="/admin" className="text-white">Verwaltung</Link></li>
              <li><a onClick={handleLogout}>Logout</a></li>
            </ul>
          </div>
        ) : (
          <a href="http://45.142.107.195:8003/login" className="btn btn-ghost ml-4">
            <FontAwesomeIcon icon={faSignInAlt} className="text-white" />
          </a>
        )}
      </div>
    </div>
  );
};

export default Navbar;