import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSave, faTimes, faCar, faIndustry, faDollarSign, faBox, faGasPump, faTag, faImage, faPlus } from '@fortawesome/free-solid-svg-icons';

const Admin = () => {
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (!storedUser) {
      navigate('/');
    }

    fetch('http://45.142.107.195:8002/vehicles')
      .then(response => response.json())
      .then(data => setVehicles(data))
      .catch(error => console.error('Fetch error:', error));
  }, [navigate]);

  const handleEdit = (vehicle) => {
    setSelectedVehicle(vehicle);
    setTimeout(() => {
      const modal = document.getElementById('edit_modal');
      if (modal) {
        modal.showModal();
      }
    }, 0);
  };

  const handleDelete = (id) => {
    fetch(`http://45.142.107.195:8002/vehicles/${id}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        setVehicles(vehicles.filter(vehicle => vehicle.id !== id));
      })
      .catch(error => console.error('Fetch error:', error));
  };

  const handleSave = () => {
    const method = selectedVehicle.id ? 'PUT' : 'POST';
    const url = selectedVehicle.id ? `http://45.142.107.195:8002/vehicles/${selectedVehicle.id}` : 'http://45.142.107.195:8002/vehicles';

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedVehicle),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (method === 'POST') {
          setVehicles([...vehicles, { ...selectedVehicle, id: data.id }]);
        } else {
          setVehicles(vehicles.map(vehicle => (vehicle.id === selectedVehicle.id ? selectedVehicle : vehicle)));
        }
        document.getElementById('edit_modal').close();
      })
      .catch(error => console.error('Fetch error:', error));
  };

  const handleAdd = () => {
    setSelectedVehicle({
      name: '',
      manufacturer: '',
      price: '',
      trunk_capacity: '',
      fuel_type: '',
      category: '',
      image_url: ''
    });
    setTimeout(() => {
      const modal = document.getElementById('edit_modal');
      if (modal) {
        modal.showModal();
      }
    }, 0);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl mb-4">Verwaltungsdashboard</h1>
      <button className="btn btn-outline btn-success mb-4" onClick={handleAdd}>
        <FontAwesomeIcon icon={faPlus} /> Hinzufügen
      </button>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th>Hersteller</th>
              <th>Preis</th>
              <th>Kofferraum</th>
              <th>Kraftstoff</th>
              <th>Kategorie</th>
              <th>Bild</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map(vehicle => (
              <tr key={vehicle.id}>
                <td>{vehicle.name}</td>
                <td>{vehicle.manufacturer}</td>
                <td>${vehicle.price}</td>
                <td>{vehicle.trunk_capacity} L</td>
                <td>{vehicle.fuel_type}</td>
                <td>{vehicle.category}</td>
                <td>
                  <div className="avatar">
                    <div className="mask mask-squircle h-12 w-12">
                      <img src={vehicle.image_url} alt={vehicle.name} />
                    </div>
                  </div>
                </td>
                <td>
                  <button className="btn btn-xs btn-warning mr-2" onClick={() => handleEdit(vehicle)}>
                    <FontAwesomeIcon icon={faEdit} /> Bearbeiten
                  </button>
                  <button className="btn btn-xs btn-error" onClick={() => handleDelete(vehicle.id)}>
                    <FontAwesomeIcon icon={faTrash} /> Löschen
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Hersteller</th>
              <th>Preis</th>
              <th>Kofferraum</th>
              <th>Kraftstoff</th>
              <th>Kategorie</th>
              <th>Bild</th>
              <th>Aktionen</th>
            </tr>
          </tfoot>
        </table>
      </div>

      {selectedVehicle && (
        <dialog id="edit_modal" className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Fahrzeug bearbeiten</h3>
            <div className="py-4">
              <label className="input input-bordered flex items-center gap-2 mb-2">
                <FontAwesomeIcon icon={faCar} />
                <input
                  type="text"
                  className="grow"
                  placeholder="Name"
                  value={selectedVehicle.name}
                  onChange={(e) => setSelectedVehicle({ ...selectedVehicle, name: e.target.value })}
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 mb-2">
                <FontAwesomeIcon icon={faIndustry} />
                <input
                  type="text"
                  className="grow"
                  placeholder="Hersteller"
                  value={selectedVehicle.manufacturer}
                  onChange={(e) => setSelectedVehicle({ ...selectedVehicle, manufacturer: e.target.value })}
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 mb-2">
                <FontAwesomeIcon icon={faDollarSign} />
                <input
                  type="number"
                  className="grow"
                  placeholder="Preis"
                  value={selectedVehicle.price}
                  onChange={(e) => setSelectedVehicle({ ...selectedVehicle, price: e.target.value })}
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 mb-2">
                <FontAwesomeIcon icon={faBox} />
                <input
                  type="number"
                  className="grow"
                  placeholder="Kofferraum"
                  value={selectedVehicle.trunk_capacity}
                  onChange={(e) => setSelectedVehicle({ ...selectedVehicle, trunk_capacity: e.target.value })}
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 mb-2">
                <FontAwesomeIcon icon={faGasPump} />
                <input
                  type="text"
                  className="grow"
                  placeholder="Kraftstoff"
                  value={selectedVehicle.fuel_type}
                  onChange={(e) => setSelectedVehicle({ ...selectedVehicle, fuel_type: e.target.value })}
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 mb-2">
                <FontAwesomeIcon icon={faTag} />
                <input
                  type="text"
                  className="grow"
                  placeholder="Kategorie"
                  value={selectedVehicle.category}
                  onChange={(e) => setSelectedVehicle({ ...selectedVehicle, category: e.target.value })}
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 mb-2">
                <FontAwesomeIcon icon={faImage} />
                <input
                  type="text"
                  className="grow"
                  placeholder="Bild URL"
                  value={selectedVehicle.image_url}
                  onChange={(e) => setSelectedVehicle({ ...selectedVehicle, image_url: e.target.value })}
                />
              </label>
            </div>
            <div className="modal-action">
              <button className="btn btn-primary" onClick={handleSave}>
                <FontAwesomeIcon icon={faSave} /> Speichern
              </button>
              <button className="btn" onClick={() => document.getElementById('edit_modal').close()}>
                <FontAwesomeIcon icon={faTimes} /> Abbrechen
              </button>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>
      )}
    </div>
  );
};

export default Admin;