import React, { useEffect, useState } from 'react';
import VehicleCard from '../components/VehicleCard';
import { useParams } from 'react-router-dom';

const Category = () => {
  const { category } = useParams();
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    fetch(`http://45.142.107.195:8002/vehicles?category=${category}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setVehicles(data))
      .catch(error => console.error('Fetch error:', error));
  }, [category]);

  return (
    <div className="container mx-auto px-2">
      <h1 className="text-4xl mb-4">{category} Fahrzeuge</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1">
        {vehicles.map(vehicle => (
          <VehicleCard key={vehicle.id} vehicle={vehicle} />
        ))}
      </div>
    </div>
  );
};

export default Category;